<template>
  <div v-if="isLoading">
    <div class="text-center text-danger screen-center">
      <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
      <strong>Cargando...</strong>
    </div>
  </div>
  <div v-else>
    <div class="breadcrumb">
      <h1>Importar Aseguradoras</h1>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6">
                <label class="btn bg-custom-green rounded col-form-label mr-2">
                  <input id="file"
                    type="file"
                    hidden
                    ref="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                    application/vnd.ms-excel"
                    @change="handleFileUpload()"
                  >
                  Seleccionar Archivo
                </label>
                <button class="btn bg-custom-green rounded mr-2"
                  v-if="existeArchivo"
                  @click="subirArchivo"
                >
                 Subir
                </button>
                <router-link :to="{ name: 'aseguradora-index' }">
                  <button class="btn btn-white border border-light rounded">
                    Regresar
                  </button>
                </router-link>
              </div>
            </div>
          </div>
          <div class="card-body" v-if="isUploading">
            <div class="text-center text-danger my-2">
              <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
              <strong>Cargando...</strong>
            </div>
          </div>
          <div class="card-body" v-else>
            <div class="row mb-3">
              <div class="d-flex" :class="{'col-sm-3': dropdownOpen, 'col-sm-1.5 ml-3': !dropdownOpen }">
                <b-dropdown id="dropdown-dropright"
                            dropright
                            text="Descargar plantilla"
                            variant="secondary"
                            class="m-2"
                            @shown="expandColumn"
                            @hidden="collapseColumn">
                  <b-dropdown-item href="#"
                                    @click="downloadPlantillaNewDatos">
                    Nuevos datos
                  </b-dropdown-item>
                </b-dropdown>
                <button class="btn btn-success ml-3" v-if="aseguradoras.length > 0"
                        :class="{ 'd-none': dropdownOpen }"
                        @click="guardarListaConfirmada">
                  Guardar
                </button>
              </div>
              <div class="d-flex align-items-center" :class="{ 'col-sm-5': !dropdownOpen, 'col-sm-6 mr-3': dropdownOpen }">
                <div class="text-danger">
                  Si es nuevo el cliente, no tiene que estar creado como seguro, al importar crea todo
                </div>
              </div>
              <div class="col-sm-2 pt-3">
                <div v-if="aseguradoras.length > 0">
                  Total Filas: <span class="font-weight-bold">{{ aseguradoras.length }}</span>
                </div>
              </div>
            </div>
            <div class="row mt-3" v-if="aseguradoras.length > 0">
              <div class="col-sm-12">
                <b-table class="table table-striped table-hover"
                  :fields="fieldsAseguradoras"
                  :items="tableAseguradoras"
                  :per-page="perPageAseguradoras">
                  <template #cell(eliminar)="items">
                    <i class="nav-icon i-Close-Window font-weight-bold text-danger"
                      @click="eliminarItem(items.index)"
                      style="cursor: pointer"></i>
                  </template>
                  <template #cell(coaseguro)="data">
                    {{data.item.coaseguro | numeral('0,0.00')}}
                  </template>
                  <template #cell(seguro)="data">
                    {{data.item.seguro | numeral('0,0.00')}}
                  </template>
                </b-table>
              </div>
              <div class="col-sm-4 offset-sm-8">
                <b-pagination
                  v-model="currentPageAseguradoras"
                  :total-rows="aseguradoras.length"
                  :per-page="perPageAseguradoras"
                  @click.native="listarTablaAseguradoras"
                  class="my-0 float-right"
                ></b-pagination>
              </div>
            </div>
            <div class="row mb-2" v-if="aseguradoras.length === 0">
              <div class="col-sm-12">
                <div class="alert alert-info" role="alert">
                  <div class="text-center">
                    <p><strong>¡IMPORTANTE!</strong></p>
                    Al importar mantener los titulos y las columnas
                    en sus respectivas ubicaciones.
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3" v-if="listaValidaciones.length > 0">
              <div class="col-sm-5 offset-sm-4">
                <b-table class="table table-striped table-hover"
                  :fields="fieldsValidaciones"
                  :items="tableValidaciones"
                  :per-page="perPageValidaciones">
                </b-table>
              </div>
              <div class="col-sm-4 offset-sm-8">
                <b-pagination
                  v-model="currentPageValidacion"
                  :total-rows="listaValidaciones.length"
                  :per-page="perPageValidaciones"
                  @click.native="listarTablaValidacion"
                  class="my-0 float-right"
                ></b-pagination>
              </div>
            </div>
            <div v-if="aseguradoras.length === 0 && listaValidaciones.length === 0"
              class="row mb-4"
            >
              <div class="col-sm-3">
                <b-table class="table table-striped table-hover"
                  :fields="fieldsCategoriaAseguradora"
                  :items="listaCategoriaAseguradora"
                >
                  <template #thead-top>
                    <tr>
                      <th colspan="3" class="py-1 text-center">
                        Lista de Categorias para Seguros
                      </th>
                    </tr>
                  </template>
                </b-table>
              </div>
              <div class="col-sm-4">
                <div class="row">
                  <div class="col-sm-12 table-scroll px-0">
                    <b-table
                      class="table table-striped table-hover"
                      :fields="fieldsClientes"
                      :items="listaClientes"
                    >
                      <template #thead-top>
                        <tr>
                          <th colspan="3" class="py-1 text-center">Lista de Clientes</th>
                        </tr>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
              <div class="col-sm-5">
                <b-table class="table table-striped table-hover"
                  :fields="fieldsTipoServicios"
                  :items="listaTipoServicios"
                >
                  <template #thead-top>
                    <tr>
                      <th colspan="2" class="py-1 text-center">Tipo de Servicios</th>
                      <th colspan="1" class="py-0 text-center">
                        <img src="@/assets/images/e32.png"
                          height="29px"
                          @click="descargarReferencia"
                          style="cursor: pointer"
                          alt="excel"
                          title="formato excel">
                      </th>
                    </tr>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'import',
  data() {
    return {
      isLoading: true,
      existeArchivo: false,
      isUploading: false,
      file: null,
      fieldsClientes: [
        {
          key: 'numero', label: 'Id', thClass: 'th-custom-color', thStyle: { width: '10%' },
        },
        {
          key: 'nombre', label: 'Nombre', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '75%' },
        },
        {
          key: 'nit', label: 'Nit', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '15%' },
        },
      ],
      listaClientes: [],
      fieldsTipoServicios: [
        {
          key: 'numero', label: 'Id', thClass: 'th-custom-color', thStyle: { width: '10%' },
        },
        {
          key: 'nombre', label: 'Nombre', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '55%' },
        },
        {
          key: 'actividad_economica_id', label: 'Actividad Economica ID', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '35%' },
        },
      ],
      listaTipoServicios: [],
      fieldsCategoriaAseguradora: [
        {
          key: 'numero', label: 'Id', thClass: 'th-custom-color', thStyle: { width: '10%' },
        },
        {
          key: 'nombre', label: 'Nombre', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '90%' },
        },
      ],
      listaCategoriaAseguradora: [],
      aseguradoras: [],
      fieldsAseguradoras: [
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'cliente_numero', label: 'Cliente Id', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' }, sortable: true,
        },
        {
          key: 'cliente', label: 'Cliente', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '23%' }, sortable: true,
        },
        {
          key: 'servicio_numero', label: 'Servicio Id', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' }, sortable: true,
        },
        {
          key: 'servicio', label: 'Servicio', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '14%' }, sortable: true,
        },
        {
          key: 'categoria_numero', label: 'Categoria Id', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' }, sortable: true,
        },
        {
          key: 'categoria', label: 'Categoria', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '14%' }, sortable: true,
        },
        {
          key: 'coaseguro', label: 'Coaseguro', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' }, sortable: true,
        },
        {
          key: 'seguro', label: 'Seguro', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' }, sortable: true,
        },
      ],
      tableAseguradoras: [],
      listaValidaciones: [],
      fieldsValidaciones: [
        {
          key: 'fila', label: 'Fila', thClass: 'bg-danger', thStyle: { width: '15%' }, sortable: true,
        },
        {
          key: 'campo', label: 'Campo', thClass: 'bg-danger', class: 'text-center', thStyle: { width: '35%' }, sortable: true,
        },
        {
          key: 'error', label: 'Error', thClass: 'bg-danger', class: 'text-center', thStyle: { width: '30%' }, sortable: true,
        },
        {
          key: 'valor', label: 'Valor', thClass: 'bg-danger', class: 'text-center', thStyle: { width: '20%' }, sortable: true,
        },
      ],
      tableValidaciones: [],
      perPageValidaciones: 100,
      currentPageValidacion: 1,
      perPageAseguradoras: 100,
      currentPageAseguradoras: 1,
      dropdownOpen: false,
    };
  },
  created() {
    this.cargarReferencias();
  },
  methods: {
    async descargarReferencia() {
      try {
        const RESPONSE = await axios.get('/clinic/insurer/load_massive/referencia', {
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'data:application/vnd.ms-excel' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        const A = document.createElement('a');
        A.href = DOWNLOAD_URL;
        A.download = 'Referencia de Seguro.xlsx';
        document.body.appendChild(A);
        A.click();
        document.body.removeChild(A);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async cargarReferencias() {
      this.isLoading = true;
      try {
        const RESPONSE = await axios.get('/clinic/insurer/load_massive/load_reference');
        this.listaCategoriaAseguradora = RESPONSE.data.data.categoria_aseguradoras;
        this.listaClientes = RESPONSE.data.data.clientes;
        this.listaTipoServicios = RESPONSE.data.data.tipo_servicios;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    handleFileUpload() {
      this.existeArchivo = true;
      const [files] = this.$refs.file.files;
      this.file = files;
    },
    async subirArchivo() {
      this.isUploading = true;
      this.currentPageAseguradoras = 1;
      this.currentPageValidacion = 1;
      try {
        const DATA = new FormData();
        DATA.append('file', this.file);
        const RESPONSE = await axios.post('/clinic/insurer/load_massive/upload_import', DATA);
        this.aseguradoras = RESPONSE.data.data.insurer;
        const FIN = this.currentPageAseguradoras * this.perPageAseguradoras;
        this.tableAseguradoras = this.aseguradoras.slice(0, FIN);
        this.listaValidaciones = [];
        this.tableValidaciones = [];
        util.showNotify(RESPONSE.data.message, 'success');
      } catch (error) {
        this.listaValidaciones = [];
        this.tableValidaciones = [];
        this.aseguradoras = [];
        this.tableAseguradoras = [];
        if (error.message === 'Network Error') {
          util.showNotify('Error al subir un archivo modificado, refresque la pagina e intente nuevamente.', 'error');
          return;
        }
        if (error.response.data.error === 422) {
          util.showNotify(error.response.data.data.file[0], 'error');
          return;
        }
        if (error.response.data.error === 2001) {
          util.showNotify(error.response.data.message, 'error');
          return;
        }
        this.listaValidaciones = error.response.data.data;
        const FIN = this.currentPageValidacion * this.perPageValidaciones;
        this.tableValidaciones = this.listaValidaciones.slice(0, FIN);
        util.showNotify(error.response.data.message, 'warn');
      } finally {
        this.isUploading = false;
      }
    },
    async downloadPlantillaNewDatos() {
      try {
        const RESPONSE = await axios.get('/clinic/insurer/load_massive/template_format', {
          responseType: 'blob',
        });
        const BLOB = new Blob([RESPONSE.data], { type: 'data:application/vnd.ms-excel' });
        const DOWNLOAD_URL = URL.createObjectURL(BLOB);
        const A = document.createElement('a');
        A.href = DOWNLOAD_URL;
        A.download = 'Formato para Importar.xlsx';
        document.body.appendChild(A);
        A.click();
        document.body.removeChild(A);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    redirect(path) {
      this.$router.push(path);
    },
    async guardarListaConfirmada() {
      this.isUploading = true;
      try {
        const REQUEST = {
          insurer: this.aseguradoras,
        };
        const RESPONSE = await axios.post('/clinic/insurer/load_massive/store_import', REQUEST);
        util.showNotify(RESPONSE.data.message, 'success');
        this.redirect({ name: 'aseguradora-index' });
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isUploading = false;
      }
    },
    listarTablaValidacion() {
      const INICIO = (this.currentPageValidacion - 1) * this.perPageValidaciones;
      const FIN = this.currentPageValidacion * this.perPageValidaciones;
      this.tableValidaciones = this.listaValidaciones.slice(INICIO, FIN);
    },
    eliminarItem(index) {
      const REFERENCIA = (this.currentPageAseguradoras - 1) * this.perPageAseguradoras;
      this.tableAseguradoras.splice(index, 1);
      this.aseguradoras.splice(index + REFERENCIA, 1);
    },
    listarTablaAseguradoras() {
      const INICIO = (this.currentPageAseguradoras - 1) * this.perPageAseguradoras;
      const FIN = this.currentPageAseguradoras * this.perPageAseguradoras;
      this.tableAseguradoras = this.aseguradoras.slice(INICIO, FIN);
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    expandColumn() {
      this.dropdownOpen = true;
    },
    collapseColumn() {
      this.dropdownOpen = false;
    },
  },
};
</script>

<style scoped>
.table-scroll
{
  height: 468px;
  overflow-y: scroll;
}
</style>
